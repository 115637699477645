import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Archive from '../../assets/images/icons/archive.svg';
import Brain from '../../assets/images/icons/brain-thought.svg';
import Eye from '../../assets/images/icons/eye.svg';
import Touch from '../../assets/images/icons/touch.svg';
import CardSecure from '../../assets/images/icons/card-secure.svg';
import Shield from '../../assets/images/icons/shield.svg';

const Covid19 = () => {
  return (
    <PageLayout>
      <SEO
        title="Blockchain and Covid-19 pandemic relief for employees"
        description="Blockchain contributes to helping businesses safely allow their employees to come to the office again, during the Covid-19 pandemic. "
      />
      <Layout dark background="blue">
        <Hero
          type="code"
          backgroundImage="https://images.dragonchain.com/SafePass.png"
          video={{
            url: 'https://www.youtube.com/watch?v=dE0PoARoVOA&feature=youtu.be',
            autoPlay: true,
          }}
        >
          <Title color="white">Blockchain and Covid-19 pandemic relief for employees</Title>
          <Description color="blue-light">
            <p>
              How Dragonchain delivered a solution for SafePass in less than one month, to help businesses
              safely allow their employees to come to the office again, during the Covid-19 pandemic.
            </p>
            <Actions
              actions={[
                {
                  type: 'link',
                  name: 'More solutions',
                  color: 'blue',
                  hover: 'blue-dark',
                  to: '/business',
                },
              ]}
            />
          </Description>
        </Hero>
      </Layout>
      <LayoutBlock>
        <Container>
          <Row gap="small">
            <Column span={12}>
              <Section title="From Concept To Operational In Less Than a Month" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: Archive,
                title: 'Compliant',
                body:
                  'In line with the FDA guidance on Digital Health Policies and Public Health Solutions for COVID-19',
              },
              {
                Icon: Eye,
                title: 'Privacy',
                body: 'Respecting individuals their privacy and right to manage your own health data.',
              },
              {
                Icon: Touch,
                title: 'Interoperability',
                body: 'Integrating blockchain solutions with traditional systems and software applications.',
              },
              {
                Icon: CardSecure,
                title: 'Non-traceable',
                body: 'Private and safe evaluation of the results, without any tracing of their information.',
              },
              {
                Icon: Shield,
                title: 'Interchain',
                body:
                  'Every interaction is secured by Dragon Net and the combined security of Bitcoin (BTC), Ethereum (ETH), Ethereum Classic (ETC).',
              },
              {
                Icon: Brain,
                title: 'Low fees',
                body:
                  'Dragonchain donated TIME for this cause, lowering transaction fees so it’s more affordable for everyone.',
              },
            ]}
          />
          <Row gap="small">
            <Column span={12}>
              <Section title="How it started">
                In the first quarter of 2020, Dragonchain was able to partner with Medek Health Systems. The
                city of Apopka was looking for an application to help enable employees get back to work, as
                safely as possible. We are honored that Medek Health Systems selected Dragonchain to ensure
                privacy and personal medical data of individuals is respected.
              </Section>
            </Column>
          </Row>
          <Row gap="small">
            <Column span={12}>
              <Section title="What does the application solve?">
                In the application, a medical assessment provides directions on next steps to take. Should an
                individual go into quarantine? Or perhaps consider getting tested or seeking medical help for
                having Covid-19 symptoms? After completing a questionnaire, a medical risk score is assigned
                to a user. Users of the application are even able to use telemedicine for Covid-19, and have
                access to medical professionals directly through the app. But where does blockchain come into
                play?
              </Section>
            </Column>
          </Row>
          <Row gap="small">
            <Column span={12}>
              <Section subtitle="How does blockchain provide value?" title="Proof and Privacy">
                Every step along the way is recorded and validated on Dragonchain’s hybrid blockchain
                protocol. A combination of ledgering and smart contracts is used to trace the workflow, and
                provide proof of the predescribed workflow. The system takes into consideration new methods
                and tests to become available over time, making it a flexible solution. Individuals are able
                to proof their employee or other authority that the prescribed process has been followed.
                Within a matter of weeks the application was launched in the Google Play Store and the Apple
                App store. Medek Health Systems continues to integrate more blockchain based solutions into
                the application, including our decentralized identity solution Factor.
              </Section>
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
      <LayoutBlock separator>
        <Container>
          <Row gap="large">
            <Column span={12}>
              <Accordion title="The stats">
                <Accordion.Item title="Man-hours">Less than a month</Accordion.Item>
                <Accordion.Item title="SafePass Deliverables">Application development</Accordion.Item>
                <Accordion.Item title="Dragonchain Deliverables">
                  <ul>
                    <li>Blockchain</li>
                    <li>2 Level 1 nodes, staging and production</li>
                  </ul>
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <Section title="Want to learn more?">
                Integrate Dragonchain's blockchain solutions in your Covid-19 applications to protect
                personally identifiable information and other sensitive health data.
              </Section>
            </Column>
            <Column span={6} right middle>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default Covid19;
